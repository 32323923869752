import React from 'react'
import { Hero, About, Tokenomics, Presale, RoadMap, FAQs, } from '../../components/Sections/index'

const Footer = React.lazy(() => import('../../components/Footer/Footer'))
const Home = () => {
    return (
        <>
            <Hero />
        </>
    )
}

export default Home;
