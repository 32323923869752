import React, { useState, useEffect } from 'react'
import './navbar.css'
import logo from '../../assets/imgs/logo_group.svg'
// import usa from '../../assets/imgs/usa.svg'
import { useTranslation } from 'react-i18next'
import { CgClose, CgMenuRight } from 'react-icons/cg'
import { Link } from 'react-router-dom'
import { languages } from '../../Utils/MockData'
import i18next from 'i18next'
import cookies from 'js-cookie'

const Navbar = ({ showLanguage, showHamburger }) => {
    const currentLangCode = cookies.get('i18next') || 'en'
    const currectLanguage = languages.find(c => c.code === currentLangCode)
    const { t } = useTranslation()
    const [showMenu, setShowMenu] = useState(false)
    const [cookieLangCode,setCode] = useState("")
    const selectLanguage = (event) => {
        console.log(event.target.value)
        var code = event.target.value
        i18next.changeLanguage(code)
        cookies.set('language', code, { expires: 7 })
    }

   
    useEffect(() => {
        document.body.dir = currectLanguage.dir || 'ltr'
        var code = cookies.get('language')
        setCode(code)
    }, [currectLanguage])
    return (
        <>
            <section className="navbar_area">
                <div className="navbar_content">
                    <Link to="https://mehracki.io" style={{ textDecoration: 'none', }}>
                        <div className="logo_img">
                            <img src={logo} alt="logo" className="logo" />
                        </div>
                    </Link>
                </div>
            </section>
        </>
    )
}

export default Navbar