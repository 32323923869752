import React from 'react'
import './information.css'
import coin2 from '../../assets/imgs/coin3.svg'
import model from '../../assets/imgs/model.svg'
import giftbox from '../../assets/imgs/giftbox.svg'
import stand from '../../assets/imgs/stand.svg'
import goldwave from '../../assets/imgs/goldwave.svg'
import { useTranslation } from 'react-i18next'
import { info_activity } from '../../Utils/MockData'

const Navbar = React.lazy(() => import('../../components/Navbar/Navbar'))
const Footer = React.lazy(() => import('../../components/Footer/Footer'))
const Information = () => {
    const { t } = useTranslation()
    return (
        <>
            <section className="info">
                <div className="info_area">
                    <Navbar showLanguage={true} showHamburger={true} />
                    <div className="div">
                        <div className="content">
                            <p>{t('infoTitle')}</p>
                            <p>{t('infoDesc')}</p>
                        </div>
                    </div>
                    <div className="background"></div>
                    <div className="coin2">
                        <img src={coin2} alt="coin_1" />
                    </div>
                </div>
                <div className="info_ft">
                    <div className="info_grid">
                        <div className="info_img">
                            <img src={model} alt="model" />
                        </div>
                        <div className="info_heading">
                            <p>{t('infoList.0.title')}</p>
                            <p>{t('infoList.0.desc')}</p>
                        </div>
                    </div>
                    <div className="info_grid reverse">
                        <div className="info_img">
                            <img src={giftbox} alt="giftbox" />
                        </div>
                        <div className="info_heading">
                            <p>{t('infoList.1.title')}</p>
                            <p>{t('infoList.1.desc')}</p>
                        </div>
                    </div>
                    <div className="info_grid">
                        <div className="info_img">
                            <img src={stand} alt="stand" />
                        </div>
                        <div className="info_heading">
                            <p>{t('infoList.2.title')}</p>
                            <p>{t('infoList.2.desc')}</p>
                        </div>
                    </div>
                </div>
                <div className="voting_ft">
                    <div className="ft_container">
                        <div className="ft_grid">
                            <img src={goldwave} alt="goldwave" />
                            <p>{t('moreTitle')}</p>
                        </div>
                    </div>
                    {/* <img src={heart} alt="heart" className="heart_floating" /> */}
                </div>
                <div className="activity_ft">
                    <div className="activity_container">
                        <div className="activity_text">
                            <p>{t('include')}</p>
                            <ul>
                                {info_activity.map((item, index) => {
                                    return (
                                        <li key={index}><p>{t(`includeList.${index}.title`)}</p></li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="join_ft">
                    <div className="join_container">
                        <div className="join_grid">
                            <p>{t('subsTitle')}</p>
                            <a href="https://raydium.io/swap/?inputCurrency=5KtxfEpgBXkqsGZZytzt3Z7cWhnSWyyWoYNGiGTectna&outputCurrency=sol&outputAmount=0&fixed=in">{t('subsBtn')}</a>
                        </div>
                    </div>
                </div>
            </section>
            <Footer showLanguage={false} showHamburger={false} />
        </>
    )
}
export default Information;