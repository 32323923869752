import React from 'react'
import './error.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


const Navbar = React.lazy(() => import('../../components/Navbar/Navbar'))

const Error = () => {
    const { t } = useTranslation()
    return (
        <>
            <div className="error_area">
                <Navbar showLanguage={true} showHamburger={true} />
                <div className="error_grid">
                    <div className="error_content">
                        <p>{t('Oops! Page Not Found 🚧')}</p>
                        <p>{t(`We cant seem to find the page you're looking for. This page does not exist or was removed.`)}</p>
                        <Link to='/'>
                            <button>{t('Visit our homepage here')}</button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Error
