import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend'



i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(XHR)
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    supportedLngs: ['en', 'fr', 'it', 'es', 'pt', 'hi', 'vi', 'tr', 'ja', 'de', 'tr','ar'],
    debug: true,
    // lng: document.querySelector('html').lang,
    fallbackLng: 'en',
    detection: {
      order: ['queryString', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie', 'localStorage', 'sessionStorage']
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
      allowMultiLoading: true
    },
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;