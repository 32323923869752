export const phase1 = [
    {
        id: 1,
        title: "Core team formation",
    },
    {
        id: 2,
        title: "Project planning",
    },
    {
        id: 3,
        title: "Token launch",
    },
    {
        id: 4,
        title: "Smart contract deployment",
    },
    {
        id: 5,
        title: "Smart contract audit by Certik",
    },
    {
        id: 6,
        title: "First campaign and branding exercise",
    },
    {
        id: 7,
        title: "Listing on Raydium",
    },
    {
        id: 8,
        title: "Social Media presence growth",
    },
]
export const phase2 = [
    {
        id: 1,
        title: "Website launch",
    },
    {
        id: 2,
        title: "Presale",
    },
    {
        id: 3,
        title: "$MKI liquidity pool (yield farming and staking) on Raydium",
    },
    {
        id: 4,
        title: "Exchange listing",
    },
    {
        id: 5,
        title: "First DAO voting takes place",
    },
]
export const phase3 = [
    {
        id: 1,
        title: "Growth hacking exercises",
    },
    {
        id: 2,
        title: "Aggressive marketing campaigns with influencers",
    },
    {
        id: 3,
        title: "Begin designs of the first feel-good NFTs",
    },
    {
        id: 4,
        title: "Issue first whitelist",
    },
    {
        id: 5,
        title: "NFT airdrops",
    },
]
export const phase4 = [
    {
        id: 1,
        title: "NFT marketplace",
    },
    {
        id: 2,
        title: "More partnerships",
    },
    {
        id: 3,
        title: "Referral payouts",
    },
]

export const faqs = [
    {
        id: 1,
        title: "What is Mehracki?",
        answer: `Mehracki is a community-driven feel-good meme token based on Solana. The ecosystem seeks to promote the habit of worrying less through championing feel-good activities worldwide.\nMehraki provides the masses with many ways to earn, from staking and yield farming to NFTs.`,
    },
    {
        id: 2,
        title: "What is a meme token?",
        answer: `A meme token is a digital asset based on blockchain technology created on the premise of a joke, theme, or meme. Meme tokens are usually community-driven and rely solely on marketing hype for increased unit valuation of the token.`,
    },
    {
        id: 3,
        title: "What is the use of Mehracki?",
        answer: `Use Mehracki for fast, cheap, and secure transactions and earn 15% APY for staking for a minimum of 30 days.`,
    },
    {
        id: 4,
        title: "Why should you invest in Mehracki?",
        answer: `Our smart contracts are audited, safe and secure transactions, nominal transaction fees, community-driven, practical uses case, built on Solana, deflationary token model, NFT marketplace, and competitive staking rewards.`,
    },
    {
        id: 5,
        title: "Who is behind the project?",
        answer: `The core team comprises seven feel-good advocates with a cumulative experience of over two decades in crypto marketing, product management, sales, and taking blockchain projects mainstream. In due time, they will be doxxed. Or not.`,
    },
    {
        id: 6,
        title: "How can I be of the Mehracki community?",
        answer: `You can be part of the Mehracki community by promoting feel-good activities and being a holder of the Mehracki tokens. Start by clicking on the icon of the social media community of which you wish to be part. Following this instruction is vital as malicious actors pedal non-official but closely matching links so they can defraud you. Watch out.`,
    },
    {
        id: 7,
        title: "Why should I join a community?",
        answer: `Our communities are the first to get verified information bordering Mehracki. You also get to meet fellow investors and token holders with whom you can share ideologies and discuss Mehracki’s potential. Ask-Me-Anything (AMA) sessions will be held periodically to inform the community of the milestones achieved and plans, with the core team members in attendance.`,
    },
]

export const info_activity = [
    "Adding and removing assets from the liquidity pool",
    "Decisions bordering strategic partnerships",
    "Yield farming",
    "Taxes on transactions and how they are used",
    "Tourist attractions and hospitality spots to receive donations"
]
export const tokenDetailsData = [
    {
        id: "1",
        title: "Total Supply",
        name: "100B Tokens"
    },
    {
        id: "2",
        title: "Ticker",
        name: "$MKI"
    },
    {
        id: "3",
        title: "Presale",
        name: "17th May to 22th August 2022"
    },
    {
        id: "4",
        title: "Launch Date:",
        name: "22th August 2022"
    },
    {
        id: "5",
        title: "Start Price",
        name: "$0.000056"
    },
]
export const presaleData = [
    {
        id: "1",
        name: "Stage 1",
        date: "17th May to 20th June",
        no_of_token: "18 Billion",
        allocation: "18%",
        bonus: "2%"
    },
    {
        id: "2",
        name: "Stage 2",
        date: "20th June to 18th July",
        no_of_token: "7.5 Billion",
        allocation: "7.5%",
        bonus: "3%"
    },
    {
        id: "3",
        name: "Stage 3",
        date: "18th July to 22th August",
        no_of_token: "4.5 Billion",
        allocation: "4.5%",
        bonus: "4%"
    },
]

export const languages = [
    {
        code: 'en',
        name: 'English', //english
        dir: 'ltr',
        country_code: 'gb',
    },
    {
        code: 'fr',
        name: 'Français', //french
        dir: 'ltr',
        country_code: 'fr',
    },
    {
        code: 'ar',
        name: 'العربية', //arabic
        dir: 'rtl',
        country_code: 'sa',
    },
    {
        code: 'de',
        name: 'Deutsch', //german
        dir: 'ltr',
        country_code: 'de',
    },
    {
        code: 'es',
        name: 'Español',//spanish
        dir: 'ltr',
        country_code: 'es',
    },
    {
        code: 'hi',
        name: 'हिन्दी',//hindi
        dir: 'ltr',
        country_code: 'in',
    },
    {
        code: 'it',
        name: 'Italiano', //italian
        dir: 'ltr',
        country_code: 'it',
    },
    {
        code: 'ja',
        name: '日本語, 日本語',//Japanese
        dir: 'ltr',
        country_code: 'jp',
    },
    {
        code: 'pt',
        name: 'Português', //Portuguese
        dir: 'ltr',
        country_code: 'pt',
    },
    {
        code: 'tr',
        name: 'Türkçe', //turkish
        dir: 'ltr',
        country_code: 'tr',
    },
    {
        code: 'vi',
        name: 'Tiếng Việt Nam', //vietnamese
        dir: 'ltr',
        country_code: 'vn',
    },
]