import React, { useState, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import './hero.css'
import Navbar from '../../Navbar/Navbar'
import coin1 from '../../../assets/imgs/coin1.svg'
import coin2 from '../../../assets/imgs/coin2.svg'
import coin3 from '../../../assets/imgs/coin3.svg'
import coin4 from '../../../assets/imgs/coin4.svg'
import comp1 from '../../../assets/imgs/comp1.svg'
import comp2 from '../../../assets/imgs/comp2.svg'
import comp3 from '../../../assets/imgs/comp3.svg'
import comp4 from '../../../assets/imgs/comp4.svg'
import comp5 from '../../../assets/imgs/comp5.svg'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from "react-router-dom"
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const API_URL = 'https://walletapi.mehracki.io/';
const HEADERS = {
  'content-type': 'application/json',
  'project': 'https://mehracki.io/'
};

const StyledTextField = styled(TextField)({
  "& label": {
    color: "white"
  },
  "&:hover label": {
    fontWeight: 700
  },
  "& label.Mui-focused": {
    color: "white"
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white"
  },
  "& input.MuiInput-input": {
    color: "white"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white"
    },
    "&:hover fieldset": {
      borderColor: "white",
      borderWidth: 2
    },
    "&.Mui-focused fieldset": {
      borderColor: "white"
    }
  }
});

const StyledButton = styled(Button)({
  "&.MuiButtonBase-root": {
    color: "white",
    textTransform: 'none'
  }
});

const Hero = () => {
    const { t } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams();
  const [walletAddress, setWalletAddress] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (searchParams.get('code')) {
      setAuthToken(searchParams.get('code'))
    }
  }, [searchParams]);

  const submitEmail = () => {
    fetch(API_URL + 'user', {
      headers: HEADERS,
      method: 'POST',
      body: JSON.stringify({
        email: email
      })
    }).then((response) => {
      response.json().then((data) => {
        if (data.success && data.message) {
          setMessage(data.message);
          setError('');
        } else if (data.message) {
          setError(data.message);
          setMessage('');
        }
      });
    })
  };

  const submitWallet = () => {
    fetch(API_URL + 'wallet', {
      headers: HEADERS,
      method: 'POST',
      body: JSON.stringify({
        uniqCode: authToken,
        wallet_address: walletAddress
      })
    }).then((response) => {
      response.json().then((data) => {
        if (data.success && data.message) {
          setMessage(data.message);
          setError('');
        } else if (data.statusCode && data.statusCode == 404) {
          setError('You have already updated your wallet address. Please try again');
          setMessage('');
          setAuthToken('');
        } else if (data.message) {
          setError(data.message);
          setMessage('');
        }
      });
    })
  };

    return (
        <>
            <section className="hero">
                {error && <Alert severity="error">
                    {error}
                </Alert>}
                {message && <Alert severity="success">
                    {message}
                </Alert>}
                <div className="hero_area">
                    <Navbar showLanguage={true} showHamburger={true}/>
                    <div className="div">
                        <div className="content">
                            <div className="steps">
                                {!authToken && <div className="step">
                                  <div className="step-label">
                                    Enter your email
                                  </div>
                                  <div className="step-content">
                                    <StyledTextField label="" margin="none" InputProps={{type: 'email'}} value={email} onChange={(event) => {setEmail(event.target.value)}} variant="standard" />
                                    <StyledButton className="submit-btn" variant="standard" onClick={() => {submitEmail()}}>
                                        Submit
                                    </StyledButton>
                                  </div>
                                </div>}
                                {authToken && <div className="step">
                                  <div className="step-label">
                                    Enter your BEP-20 wallet address
                                  </div>
                                  <div className="step-content">
                                    <StyledTextField label="" margin="none" InputProps={{type: 'email'}} value={walletAddress} onChange={(event) => {setWalletAddress(event.target.value)}} variant="standard" />
                                    <StyledButton className="submit-btn" variant="standard" onClick={() => {submitWallet()}}>
                                        Save
                                    </StyledButton>
                                  </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="background"></div>
                    <div className="coin1">
                        <img src={coin1} alt="coin_1" />
                    </div>
                    <div className="coin2">
                        <img src={coin3} alt="coin_3" />
                    </div>
                    <div className="coin3">
                        <img src={coin4} alt="coin_3" />
                    </div>
                    <div className="coin4">
                        <img src={coin2} alt="coin_4" />
                    </div>

                </div>
            </section>

        </>
    )
}

export default Hero;