import React, { Suspense } from 'react'
import './App.css';
import AppRouter from './AppRouter/Router'


function App() {
  return (
    <div className="App">
      <Suspense fallback={
        <div className="loader">
          <p>Loading...</p>
        </div>
      }>
        <AppRouter/>
      </Suspense>

    </div>
  );
}

export default App;
