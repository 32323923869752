import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { HomePage,InformationPage,ErrorPage } from '../Pages/index'

 const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" index element={<HomePage/>}/>
                <Route path="/information" element={<InformationPage/>}/>
                <Route path="/*" element={<ErrorPage/>}/>
            </Routes>
        </BrowserRouter>
    )
}
export default Router;